import logo from "./logo.svg";
import { FaPlus } from "react-icons/fa";
import {
  motion,
  useTransform,
  useScroll,
  AnimatePresence,
} from "framer-motion";
import "./App.css";
import cw1 from "./asset/coldWallet1.png";
import cw2 from "./asset/coldWalletBg.png";
import h1 from "./asset/avatar1.png";
import h2 from "./asset/avatar2.png";
import h3 from "./asset/avatar3.png";
import c1 from "./asset/card1.png";
import c2 from "./asset/card2.png";
import c3 from "./asset/card3.png";
import c4 from "./asset/card4.png";
import sg from "./asset/singapore.png";
import mask from "./asset/bgMask.svg";
import screen from "./asset/screen.svg";
import bluetooth from "./asset/bluetooth.svg";
import chip from "./asset/chip.svg";
import tag from "./asset/tag.svg";
import plantform from "./asset/plantform.svg";
import React, { useState, useEffect, useRef } from "react";

const HomeStyles = `
/* Grid.css */
.grid-container {
  display: grid;
  grid-template-columns: repeat(100, 1fr); /* 创建 100 列 */
  grid-template-rows: repeat(100, 1fr); /* 创建 100 行 */
  gap: 2px; /* 设置格子之间的间隙 */
}

.grid-cell {
  width: 80px; /* 每个格子的宽度 */
  height: 80px; /* 每个格子的高度 */
   
}

  .gradient-title {
    background: linear-gradient(90deg, #F6F6F6, #BABABA 25%, #717171 30%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    animation: gradientAnimation 10s ease infinite;
  }

  .gradient-1 {
    background-image: url(${mask}), linear-gradient(135deg,#FFDE90 0%, #CE6A22 100%);
    background-size: cover;
    background-position: center;
  }
  
  .gradient-2 {
    background-image: url(${mask}), linear-gradient(135deg, #D7B3FC 0%, #782586 100%);
    background-size: cover;
    background-position: center;
  }
  
  .gradient-3 {
    background-image: url(${mask}), linear-gradient(135deg, #FAB7C7 0%, #D76E8A 100%);
    background-size: cover;
    background-position: center;
  }
  
  .gradient-4 {
    background-image: url(${mask}), linear-gradient(135deg, #CCE5C6 0%, #84AC72 100%);
    background-size: cover;
    background-position: center;
  }
  
  .gradient-5 {
    background-image: url(${mask}), linear-gradient(135deg, #D4EAF6 0%, #398CC3 100%);
    background-size: cover;
    background-position: center;
  }
  
 
 
.carousel-outer-container {
  width: 100%; /* 视图的宽度 */
  overflow-x: hidden; /* 隐藏水平滚动条 */
  position: relative;
}

.carousel-container {
  display: flex;
 gap:20px;
  white-space: nowrap; /* 防止内容折行 */
  animation: scrollRightToLeft 10s linear infinite;
}

.carousel-item {
  width: 60px; /* 单个项目的宽度 */
  height: 60px;
  flex: 0 0 auto; /* 防止 flexbox 压缩项目 */
}

@keyframes scrollRightToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%); /* 移动至总宽度的一半 */
  }
}

.custom-shadow {
  filter: 
  drop-shadow(0px 0px 10px rgba(0, 0, 0, 1))
  drop-shadow(0px 0px 30px rgba(29, 29, 29, 1))
  drop-shadow(0px 0px 50px rgba(29, 29, 29, 1))
  ;
}

.rotated-icon {
  transform: rotate(45deg); /* Or 90 degrees based on your preference */
  transition: transform 0.3s ease-in-out;
}
`;
//rgba(29, 29, 29, 0.5);
function App() {
  const [faqStates, setFaqStates] = useState({
    faq1: false,
    faq2: false,
    faq3: false,
    faq4: false,
    faq5: false,
  });

  const fadeInVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5, ease: "easeInOut" } },
  };

  // 创建引用
  const [isOpen, setIsOpen] = useState(false); // State to track toggle

  const toggleFAQ = (faqKey) => {
    setFaqStates((prevStates) => ({
      ...prevStates,
      [faqKey]: !prevStates[faqKey],
    }));
  };

  const productRef = useRef(null);
  const featureRef = useRef(null);
  const aboutUsRef = useRef(null);
  const faqRef = useRef(null);

  // 定义一个函数用于滚动到指定的元素
  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { scrollY } = useScroll();

  const [currentCard, setCurrentCard] = useState(1);
  useEffect(() => {
    const handleScroll = () => {
      const y = scrollY.get();
      if (y >= 1600 && y < 1675) {
        // 增加重叠区间，使两个卡片在透明度变化时有重叠
        setCurrentCard(2);
      } else if (y >= 1675 && y < 1750) {
        // 从1675开始，第二个卡片淡入，第三个卡片准备淡入
        setCurrentCard(3);
      } else if (y >= 1750) {
        // 1750开始，第四个卡片淡入
        setCurrentCard(4);
      } else {
        setCurrentCard(1);
      }
    };

    const unsubscribeY = scrollY.onChange(handleScroll);

    return () => {
      unsubscribeY();
    };
  }, [scrollY]);

  const scale = useTransform(scrollY, [0, 400], [1, 1.15]);

  // 由于原本的 CSS 使用 translateX(-50%) 来居中，我们需要在这里也加上相同的设置
  const x = useTransform(scrollY, () => "-50%");

  const numRows = 15; // 定义网格的行数
  const numCols = 100; // 定义网格的列数
  let cells = [];

  for (let row = 0; row < numRows; row++) {
    for (let col = 0; col < numCols; col++) {
      cells.push(
        <motion.div
          whileHover={{ backgroundColor: "#333" }} //
          key={`${row}-${col}`}
          className="grid-cell bg-[#1d1d1d] z-[0]"
        ></motion.div>
      );
    }
  }

  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY >= 0) {
        ref.current.style.position = "sticky";
        ref.current.style.top = "64px"; // 这将元素固定在顶部
      } else {
        ref.current.style.position = "relative";
        ref.current.style.top = "auto";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <style>{HomeStyles}</style>
      <div className="w-full h-[64px] bg-[#1D1D1D] text-[#B6B6B6] px-[30px] py-[20px] font-bold flex fixed z-[10]">
        LKKIM
        <div className="sm:hidden flex w-full justify-center gap-[40px] font-[400]">
          <p onClick={() => scrollToRef(productRef)}>Product</p>
          <p onClick={() => scrollToRef(featureRef)}>Feature</p>
          <p onClick={() => scrollToRef(aboutUsRef)}>About us</p>
          <p onClick={() => scrollToRef(faqRef)}>FAQ</p>
        </div>
      </div>

      <div ref={ref} className="z-[0]">
        <div className="w-full bg-[#222] text-center overflow-hidden relative h-[1000px]">
          <motion.img
            src={cw1}
            alt="Cold Wallet"
            className="w-[800px] top-[460px] sm:top-[700px] left-1/2 absolute z-[1]"
            style={{ scale, x }} // 应用缩放和水平位移
          />

          <motion.div
            className="w-[20px] h-[20px] bg-[#F0D665] rounded-full shadow-lg filter blur-[15px] z-[0] absolute"
            initial={{ x: 80, y: -50 }} // 初始位置，稍微在屏幕顶部之上
            animate={{ y: [null, window.innerHeight + 50] }} // 结束位置，稍微在屏幕底部之下
            transition={{ duration: 6, repeat: Infinity, repeatType: "loop" }} // 增加持续时间到5秒
          />
          <motion.div
            className="w-[20px] h-[20px] bg-[#F0D665] rounded-full shadow-lg filter blur-[15px] z-[0] absolute"
            initial={{ x: 240, y: 160 }} // 初始位置，从屏幕左侧80px开始
            animate={{
              x: [null, window.innerWidth - 160], // 结束位置，屏幕宽度减去80px
            }}
            transition={{
              duration: 6, // 动画持续时间
              repeat: Infinity,
              repeatType: "loop",
              delay: 2, // 延时0.5秒开始动画
            }}
          />
          <div className="grid-container z-[0] absolute ">{cells} </div>
          <div className="pointer-events-none relative">
            <div className="gradient-title sm:text-[10vw] sm:leading-normal text-[5vw] font-[500] leading-none pt-[60px] 2xl:text-[68px] mb-[20px]">
              LKKIM
              <br />
              Manage Your Crypto
              <br />
              like never before
            </div>
            {/*         <p className="text-[28px] font-bold">asdfghjkll</p> */}
            <div className="w-full px-[80px] text-center flex  justify-center flex-col items-center">
              <p className="text-[18px] font-bold text-[#A5A5A5] font-[400] max-w-[1024px] mb-[50px]">
                Begin your journey to financial freedom with our app. Secure
                your assets with our advanced cold wallet technology, and manage
                your finances more smartly and effectively. Your financial
                wellbeing is our priority.
              </p>

              <div className="relative flex flex-col items-center justify-center">
                <button
                  onClick={() => scrollToRef(productRef)}
                  className="shadow-[0_0_0_5px_#ffffff50] w-[250px] h-[60px] bg-white mb-[200px] rounded-full flex items-center justify-center font-bold text-[22px] pointer-events-auto z-10 relative"
                >
                  Learn more
                </button>
                <motion.div
                  className="w-[30px] h-[30px] bg-[#fff] rounded-full shadow-lg filter blur-[15px] z-[0] absolute"
                  initial={{ x: 80, y: -50 }}
                  animate={{
                    rotate: 360,
                    x: [95, 115, 95, -95, -115, -95, 95],
                    y: [-115, -100, -85, -85, -100, -115, -115],
                  }}
                  transition={{
                    duration: 6,
                    repeat: Infinity,
                    repeatType: "loop",
                    ease: "linear",
                  }}
                  style={{
                    top: "50%",
                    left: "50%",
                    translateX: "-50%",
                    translateY: "-50%",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <div
                style={{
                  background: "white",
                  borderRadius: "50%",
                  padding: "80px", // 控制圆的大小
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  filter: "blur(8px)", // 控制模糊程度
                }}
              >
                {/* 可以在这个圆内添加更多内容 */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full z-[2] ">
        <div
          ref={productRef}
          id="Product"
          className="w-full h-[950px] sm:h-auto  pt-[88px] px-[32px] bg-[#1D1D1D] text-center z-[2] relative"
        >
          <p className="gradient-title sm:text-[10vw]  text-white text-[5vw] pb-[40px]">
            Lock Your Crypto, Securely
          </p>
          <div className="w-full flex justify-center">
            <div
              className="h-[650px] rounded-[48px] max-w-[1280px] w-full"
              style={{
                backgroundImage: `url(${cw2})`,
                backgroundSize: "cover", // 确保背景图像覆盖整个元素
                backgroundPosition: "center", // 背景图像居中
                backgroundRepeat: "no-repeat", // 背景不重复
              }}
            ></div>
          </div>
        </div>

        <div className="w-full flex justify-center items-center h-[800px] bg-[#1D1D1D] relative overflow-hidden">
          <motion.div
            className="w-auto object-cover custom-shadow absolute top-1/2 transform -translate-y-1/2 z-[2]"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {currentCard === 1 && (
              <motion.img
                src={c1}
                alt="Card 1"
                className="h-[300px]"
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
              />
            )}
            {currentCard === 2 && (
              <motion.img
                src={c2}
                alt="Card 2"
                className="h-[300px]"
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
              />
            )}
            {currentCard === 3 && (
              <motion.img
                src={c3}
                alt="Card 3"
                className="h-[300px]"
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
              />
            )}
            {currentCard === 4 && (
              <motion.img
                src={c4}
                alt="Card 4"
                className="h-[300px]"
                variants={fadeInVariants}
                initial="hidden"
                animate="visible"
              />
            )}
          </motion.div>
          <div className="flex gap-[60px] absolute top-[50%] transform -translate-y-1/2 ">
            <p className="text-white font-bold sm:text-[10vw] text-[5vw] ">
              Security Features
            </p>
            <p className="text-white font-bold sm:text-[10vw] text-[5vw] ">
              Cross-Platform
            </p>
            <p className="text-white font-bold sm:text-[10vw] text-[5vw] ">
              Offline storage
            </p>
            <p className="text-white font-bold sm:text-[10vw] text-[5vw]">
              Against hacking
            </p>
            <p className="text-white font-bold sm:text-[10vw] text-[5vw] ">
              Unbreachable Security
            </p>
          </div>
        </div>
        <div
          ref={featureRef}
          id="Feature"
          className="w-full  h-[1100px] sm:h-auto bg-[#1D1D1D] z-[2] relative"
        >
          <p className="sm:text-[10vw] gradient-title text-white text-[4vw] pb-[40px] text-center font-[500] ">
            Feature
          </p>
          <div className="sm:h-auto w-full flex justify-center flex-col items-center">
            <div className="flex sm:flex-col gap-[2vw] max-w-[1280px] justify-center pb-[40px]">
              <div className="gradient-1 sm:w-[90vw] sm:mb-[20px] w-[68vw] h-[400px] rounded-3xl relative overflow-hidden">
                <motion.img
                  src={screen}
                  alt="screenIcon"
                  className="h-auto w-[180px] z-[2] absolute ml-20 mt-10 " // Use 'absolute' to position image if needed
                  initial={{ rotate: 135, scale: 1.6 }} // Optional: ensure scale is set initially
                  whileHover={{ rotate: 90, scale: 1 }} // Optional: scale up slightly when hovered
                  style={{
                    originX: 0.5,
                    originY: 0.4,
                    filter: "drop-shadow(0 4px 20px rgba(255, 99, 71, 0.5))",
                  }}
                />
                <div className="bg-GGrayL w-full h-[120px] rounded-bl-[22px] rounded-br-[22px] p-[22px] flex flex-col  justify-between absolute bottom-[0px]">
                  <p className="text-white font-bold text-[20px]">
                    Touchscreen Interface
                  </p>
                  <p className="text-[#aaa]  text-[16px]">
                    Experience effortless crypto management with our intuitive
                    touchscreen interface, designed for quick and easy
                    navigation through your digital assets, enabling swift
                    transactions and secure management at your fingertips.
                  </p>
                </div>
              </div>

              <div className=" gradient-2 w-[20vw] sm:w-[90vw] h-[400px] rounded-3xl relative overflow-hidden">
                <motion.img
                  src={bluetooth}
                  alt="screenIcon"
                  className="h-auto w-[180px] z-[2] absolute ml-10 mt-10 " // Use 'absolute' to position image if needed
                  initial={{ rotate: 135, scale: 2 }} // Optional: ensure scale is set initially
                  whileHover={{ rotate: 180, scale: 1 }} // Optional: scale up slightly when hovered
                  style={{
                    originX: 0.5,
                    originY: 0.5,
                    filter: "drop-shadow(0 4px 20px rgba(128, 0, 128, 0.5))",
                  }}
                />
                <div className="bg-GGrayL w-full h-[120px] rounded-bl-[22px] rounded-br-[22px] p-[22px] flex flex-col  justify-between absolute bottom-[0px]">
                  <p className="text-white font-bold text-[20px]">
                    Bluetooth Connectivity
                  </p>
                  <p className="text-[#aaa]  text-[16px]">
                    Wireless syncing with your devices for real-time updates.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex sm:flex-col  gap-[2vw] max-w-[1280px] justify-center">
              <div className=" gradient-3 sm:w-[90vw] sm:mb-[20px] w-[20vw] h-[400px] rounded-3xl relative overflow-hidden">
                <motion.img
                  src={tag}
                  alt="screenIcon"
                  className="h-auto  w-[180px] z-[2] absolute ml-20 mt-10 " // Use 'absolute' to position image if needed
                  initial={{ rotate: 135, scale: 2 }} // Optional: ensure scale is set initially
                  whileHover={{ rotate: 180, scale: 1 }} // Optional: scale up slightly when hovered
                  style={{
                    originX: 0.5,
                    originY: 0.5,
                    filter: "drop-shadow(0 4px 20px rgba(255, 192, 203, 0.5))",
                  }}
                />
                <div className="bg-GGrayL w-full h-[120px] rounded-bl-[22px] rounded-br-[22px] p-[22px] flex flex-col  justify-between absolute bottom-[0px]">
                  <p className="text-white font-bold text-[20px]">
                    Airtag Tracking
                  </p>
                  <p className="text-[#aaa]  text-[16px]">
                    Track your wallet easily with built-in Airtag.
                  </p>
                </div>
              </div>
              <div className=" gradient-4  sm:w-[90vw] sm:mb-[20px] w-[46vw] h-[400px] rounded-3xl relative overflow-hidden">
                <motion.img
                  src={chip}
                  alt="screenIcon"
                  className="h-auto w-[100px] z-[2] absolute ml-20 mt-10 " // Use 'absolute' to position image if needed
                  initial={{ rotate: 135, scale: 2 }} // Optional: ensure scale is set initially
                  whileHover={{ rotate: 90, scale: 1.5 }} // Optional: scale up slightly when hovered
                  style={{
                    originX: 0.5,
                    originY: 0.5,
                    filter: "drop-shadow(0 4px 20px rgba(144, 238, 144, 0.5)",
                  }}
                />
                <div className="bg-GGrayL w-full h-[120px] rounded-bl-[22px] rounded-br-[22px] p-[22px] flex flex-col  justify-between absolute bottom-[0px]">
                  <p className="text-white font-bold text-[20px]">
                    Secure Chip Technology
                  </p>
                  <p className="text-[#aaa]  text-[16px]">
                    Equipped with an advanced security chip, ensuring robust
                    protection for your valuable digital assets against
                    unauthorized access and cyber threats.
                  </p>
                </div>
              </div>
              <div className="sm:w-[90vw] sm:mb-[20px] gradient-5 w-[20vw] h-[400px] rounded-3xl relative overflow-hidden">
                <motion.img
                  src={plantform}
                  alt="screenIcon"
                  className="h-auto w-[180px] z-[2] absolute ml-20 mt-10 " // Use 'absolute' to position image if needed
                  initial={{ rotate: 15, scale: 1.5 }} // Optional: ensure scale is set initially
                  whileHover={{ rotate: 0, scale: 1 }} // Optional: scale up slightly when hovered
                  style={{
                    originX: 0.5,
                    originY: 0.5,
                    filter: "drop-shadow(0 4px 20px rgba(0, 0, 255, 0.5)",
                  }}
                />
                <div className="bg-GGrayL w-full h-[120px] rounded-bl-[22px] rounded-br-[22px] p-[22px] flex flex-col  justify-between absolute bottom-[0px]">
                  <p className="text-white font-bold text-[20px]">
                    Cross-Platform Support
                  </p>
                  <p className="text-[#aaa]  text-[16px]">
                    Works with various platforms for easy accessibility.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="Testimonials"
          className="w-full h-[800px] sm:h-auto bg-[#1D1D1D] z-[2] relative"
        >
          <p className="gradient-title text-white sm:text-[10vw] text-[4vw] pb-[40px] text-center font-[500]  ">
            Testimonials
          </p>
          <div className="w-full flex justify-center">
            <div className="sm:flex-col flex gap-[2vw] max-w-[1280px]  justify-center">
              <div className="bg-GGray sm:w-[90vw] w-[28vw] h-[400px] md:h-[500px] rounded-3xl p-[40px] flex flex-col  justify-between ">
                <p className="text-[#AFAFAF] text-[18px]">
                  "I've been using this cold wallet for months now, and I'm
                  impressed by its reliability and security features. Highly
                  recommended!"
                </p>
                <div className="flex items-center">
                  <img
                    src={h1}
                    alt="avatar1"
                    className="w-[48px] h-[48px] bg-[#666] rounded-full"
                  />
                  <div>
                    <p className="text-white pl-[20px]">John Doe</p>
                    <p className="text-[#909090] pl-[20px] ">
                      Cryptocurrency Enthusiast
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-GGray w-[27vw]  sm:w-[90vw] h-[400px] md:h-[500px] rounded-3xl  p-[40px] flex flex-col  justify-between">
                <p className="text-[#AFAFAF] text-[18px]">
                  "This cold wallet exceeded my expectations! Its user-friendly
                  interface and top-notch security make it a must-have for
                  anyone serious about crypto."
                </p>
                <div className="flex items-center">
                  <img
                    src={h2}
                    alt="avatar2"
                    className="w-[48px] h-[48px] bg-[#666] rounded-full"
                  />
                  <div>
                    <p className="text-white pl-[20px]">Jane Smith</p>
                    <p className="text-[#909090] pl-[20px] ">
                      Crypto Security Advocate
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-GGray w-[28vw]  sm:w-[90vw] h-[400px] md:h-[500px] rounded-3xl  p-[40px] flex flex-col  justify-between ">
                <p className="text-[#AFAFAF] text-[18px]">
                  "I've tried several cold wallets before, but this one stands
                  out. Its sleek design and advanced features make managing my
                  crypto assets a breeze."
                </p>
                <div className="flex items-center">
                  <img
                    src={h3}
                    alt="avatar3"
                    className="w-[48px] h-[48px] bg-[#666] rounded-full"
                  />
                  <div>
                    <p className="text-white pl-[20px]">Alex Johnson</p>
                    <p className="text-[#909090] pl-[20px] ">
                      Investment Analyst
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={aboutUsRef}
          id="About us"
          className="w-full h-[800px]  sm:h-auto md:h-auto bg-[#1D1D1D] z-[2] relative"
        >
          <p className="gradient-title  sm:text-[10vw]  text-white text-[4vw] pb-[40px] text-center font-[500]   ">
            About us
          </p>

          <div className="w-full flex justify-center flex-col items-center">
            <div className="sm:flex-col md:flex-col flex gap-[2vw] max-w-[1280px] justify-center pb-[40px]">
              <div className="bg-GGray sm:w-[90vw] md:w-[90vw] sm:mb-[20px] w-[68vw] sm:h-auto md:h-auto h-[300px] rounded-3xl p-[40px]">
                <p className="text-[#eee] text-[18px]">
                  At LKKIM, we are dedicated to providing top-quality solutions
                  for managing your cryptocurrency assets securely.
                  <br />
                  <br />
                  Our headquarters are in Zug, Switzerland. With a team of
                  experienced professionals, we strive to innovate and adapt to
                  the ever-changing landscape of the digital currency market.
                  <br />
                  <br />
                  We support over 10,000 cryptocurrencies, ensuring
                  compatibility and flexibility for all users.
                </p>
              </div>
              <div className="bg-GGray sm:w-[90vw] md:w-[90vw]  sm:mb-[20px] w-[20vw] h-[300px] rounded-3xl p-[40px] flex justify-center items-center">
                <img
                  src={sg}
                  alt="Switzerland"
                  className="w-[160px] h-[160px] bg-white rounded-full"
                ></img>
              </div>
            </div>
            <div className="sm:flex-col md:flex-col flex gap-[2vw] max-w-[1280px] justify-center">
              <div className="bg-GGray sm:w-[90vw] md:w-[90vw] sm:mb-[20px] w-[20vw] h-[300px] rounded-3xl p-[40px]  flex text-center flex-col justify-center">
                <p className="text-[#eee] text-[52px] font-bold ">100+</p>
                <p className="text-[#eee] text-[22px]">
                  Blockchain Networks Supported
                </p>
              </div>
              <div className="bg-GGray w-[46vw] sm:w-[90vw] md:w-[90vw] sm:mb-[20px] h-[300px]  rounded-3xl p-[40px] flex text-center flex-col">
                <p className="text-[#eee] text-[22px]">
                  Supporting Over 10,000 Cryptocurrencies
                </p>
                <div className="h-full flex items-center justify-center overflow-hidden relative">
                  <div className="carousel-outer-container">
                    <div className="carousel-container">
                      {Array.from(
                        { length: 40 },
                        (
                          _,
                          i // 创建 40 个元素（实际上是两组20个）
                        ) => (
                          <div key={i} className="carousel-item">
                            <img
                              src={`./Icon/Icon${(i % 20) + 1}.png`} // 使用模运算符确保图标循环（1 到 20）
                              alt={`Icon ${(i % 20) + 1}`}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-GGray w-[20vw]  sm:w-[90vw] md:w-[90vw] sm:mb-[20px] h-[300px] rounded-3xl p-[40px]  flex text-center flex-col justify-center">
                <p className="text-[#eee] text-[52px] font-bold ">20+ Years</p>
                <p className="text-[#eee] text-[22px]">
                  of Expertise in Security
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          ref={faqRef}
          id="FAQ"
          className="w-full h-auto pb-[60px]  bg-[#1D1D1D] z-[2] relative"
        >
          <p className="pt-[60px] gradient-title  sm:text-[10vw] text-white text-[4vw] pb-[40px] text-center font-[500]   ">
            Frequently Asked Questions
          </p>
          <div className="w-full flex items-center justify-center flex-col">
            <div className=" flex flex-col w-[60vw]  md:w-[90vw] sm:w-[90vw]  max-w-[1280px] mb-[16px] h-auto bg-[#111] rounded-xl text-white px-[30px] py-[20px] flex items-center">
              <div
                className="flex w-full items-center  cursor-pointer"
                onClick={() => toggleFAQ("faq1")}
              >
                <FaPlus
                  size="12px"
                  color="white"
                  className={`mr-[10px] ${
                    faqStates.faq1 ? "rotated-icon" : ""
                  }`}
                />
                What is a Cold Wallet?
              </div>

              <AnimatePresence>
                {faqStates.faq1 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="FAQAnswer"
                  >
                    A: A cold wallet is a type of cryptocurrency wallet that
                    stores your digital assets offline, making it less
                    vulnerable to hacks and other online threats. Unlike hot
                    wallets, which are connected to the internet and convenient
                    for frequent transactions, cold wallets are best suited for
                    long-term storage as they provide enhanced security.
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          <div className="w-full flex items-center justify-center flex-col">
            <div className=" flex flex-col w-[60vw]  md:w-[90vw] sm:w-[90vw]  max-w-[1280px] mb-[16px] h-auto bg-[#111] rounded-xl text-white px-[30px] py-[20px] flex items-center">
              <div
                className="flex w-full items-center  cursor-pointer"
                onClick={() => toggleFAQ("faq2")}
              >
                <FaPlus
                  size="12px"
                  color="white"
                  className={`mr-[10px] ${
                    faqStates.faq2 ? "rotated-icon" : ""
                  }`}
                />
                How to Set Up Your Cold Wallet?
              </div>

              <AnimatePresence>
                {faqStates.faq2 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="FAQAnswer"
                  >
                    A: Setting up your cold wallet typically involves
                    initializing the device on a secure, offline machine. You'll
                    need to generate and securely store a recovery phrase, which
                    is essential for accessing your assets in case of device
                    loss or failure. Detailed instructions are provided with
                    your purchase, ensuring a smooth setup process.
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          <div className="w-full flex items-center justify-center flex-col">
            <div className=" flex flex-col w-[60vw]  md:w-[90vw] sm:w-[90vw]  max-w-[1280px] mb-[16px] h-auto bg-[#111] rounded-xl text-white px-[30px] py-[20px] flex items-center">
              <div
                className="flex w-full items-center  cursor-pointer"
                onClick={() => toggleFAQ("faq3")}
              >
                <FaPlus
                  size="12px"
                  color="white"
                  className={`mr-[10px] ${
                    faqStates.faq3 ? "rotated-icon" : ""
                  }`}
                />
                Which cryptocurrencies are compatible with your cold wallets?
              </div>

              <AnimatePresence>
                {faqStates.faq3 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="FAQAnswer"
                  >
                    A: Our cold wallets support a wide range of
                    cryptocurrencies, including major ones like Bitcoin,
                    Ethereum, and Litecoin, as well as various altcoins. For a
                    complete list of supported currencies, please visit our
                    website or refer to the product documentation.
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          <div className="w-full flex items-center justify-center flex-col">
            <div className=" flex flex-col w-[60vw]  md:w-[90vw] sm:w-[90vw]  max-w-[1280px] mb-[16px] h-auto bg-[#111] rounded-xl text-white px-[30px] py-[20px] flex items-center">
              <div
                className="flex w-full items-center  cursor-pointer"
                onClick={() => toggleFAQ("faq4")}
              >
                <FaPlus
                  size="12px"
                  color="white"
                  className={`mr-[10px] ${
                    faqStates.faq4 ? "rotated-icon" : ""
                  }`}
                />
                How do I ensure my cold wallet is secure?
              </div>

              <AnimatePresence>
                {faqStates.faq4 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="FAQAnswer"
                  >
                    A: To secure your cold wallet, always keep the recovery
                    phrase in a safe and private location, never share it with
                    anyone, and consider using additional security measures such
                    as biometric locks or secure enclosures. Regularly updating
                    the wallet's firmware from the official website also helps
                    protect against vulnerabilities.
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          <div className="w-full flex items-center justify-center flex-col">
            <div className=" flex flex-col w-[60vw]  md:w-[90vw] sm:w-[90vw]  max-w-[1280px] mb-[16px] h-auto bg-[#111] rounded-xl text-white px-[30px] py-[20px] flex items-center">
              <div
                className="flex w-full items-center  cursor-pointer"
                onClick={() => toggleFAQ("faq5")}
              >
                <FaPlus
                  size="12px"
                  color="white"
                  className={`mr-[10px] ${
                    faqStates.faq5 ? "rotated-icon" : ""
                  }`}
                />
                What should I do if my cold wallet is not working or if I
                encounter an error?
              </div>

              <AnimatePresence>
                {faqStates.faq5 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="FAQAnswer"
                  >
                    A: If you experience any issues with your cold wallet, refer
                    to the troubleshooting section of our user guide. For more
                    specific problems, contact our support team through our
                    website. Ensure you have your device information handy to
                    expedite the support process.
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
        <div
          id="footer"
          className="w-full sm:px-[5vw] h-[300px] bg-[#121212] relative  flex justify-center z-[2] relative"
        >
          <div className=" flex w-full justify-center gap-[40px] font-[400]  text-[#827D7D] pt-[100px]">
            <p onClick={() => scrollToRef(productRef)}>Product</p>
            <p onClick={() => scrollToRef(featureRef)}>Feature</p>
            <p onClick={() => scrollToRef(aboutUsRef)}>About us</p>
            <p onClick={() => scrollToRef(faqRef)}>FAQ</p>
          </div>
          <p className="text-[#827D7D] absolute bottom-[60px] sm:w-[90vw]">
            All rights reserved. © Copyright LKKIM 2024
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
